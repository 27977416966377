<div class="title-container">
  <div class="header">{{ 'User Details' | translate }}</div>
  <svg (click)="close()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="svg-icon">
    <path
      fill="#000"
      fill-rule="evenodd"
      d="M9.031 8l6.756-6.756a.731.731 0 0 0 0-1.031.732.732 0 0 0-1.031 0L8 6.969 1.244.213a.732.732 0 0 0-1.031 0 .731.731 0 0 0 0 1.03L6.969 8 .213 14.756a.731.731 0 0 0 0 1.031.732.732 0 0 0 1.031 0L8 9.031l6.756 6.756a.732.732 0 0 0 1.031 0 .731.731 0 0 0 0-1.03L9.031 8z"
    ></path>
  </svg>

  <div class="modal-container">
    <div class="modal-body">
      <div class="row">
        <div class="col-4">{{ 'Name' | translate }}</div>
        <div class="col-8">{{ data?.first_name | titlecase }} {{ data?.last_name | titlecase }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{ 'Gender' | translate }}</div>
        <div class="col-8">{{ data?.gender | titlecase }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{ 'Email' | translate }}</div>
        <div class="col-8">{{ data?.email }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{ 'Mobile' | translate }}</div>
        <div class="col-8">{{ data?.mobile }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{ 'Status' | translate }}</div>
        <div class="col-8">{{ data?.status | titlecase }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{ 'Created at' | translate }}</div>
        <div class="col-8">{{ data?.created_at | date: 'short' }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{ 'Default Address' | translate }}</div>
        <div class="col-8">
          {{ data?.address_1 }} {{ data?.address }} {{ data?.landmark }} <br />{{ data?.city_name }} {{ data?.state }},
          {{ data?.zip_code }} <br />
          {{ data?.country }}
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div (click)="close()" class="btn btn-primary">Close</div>
    </div>
  </div>
</div>
