import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-staff-member-detail-popup',
  templateUrl: './staff-member-detail-popup.component.html',
  styleUrls: ['./staff-member-detail-popup.component.scss'],
})
export class StaffMemberDetailPopupComponent implements OnInit {
  country_id;
  bank_or_credit_Doc: any;
  certificateDoc: any;
  billDoc: any;
  licenceDoc: any;
  imgUrl;
  constructor(
    public dialogRef: MatDialogRef<StaffMemberDetailPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.imgUrl = 'https://quickfix-stage.s3.ap-southeast-1.amazonaws.com/' + this.data.image_name;
  }
  close(): void {
    this.dialogRef.close();
  }
  onImgError(event) {
    event.target.src = 'assets/images/profile_image.jpg';
  }
}
