<div mat-dialog-content class="thankTxt tac">
  <p class="material-icons"
    [ngClass]="{'thanksIcon': data['icon'] == 'check_circle_outline', 'warningIcon': data['icon'] == 'error_outline', 'deleteIcon': data['icon'] == 'delete_outline' }"
    *ngIf="data['icon']" [textContent]="data['icon']"></p>
  <p class="popupHeading" [textContent]="data['heading']"></p>
  <p [innerHTML]="data['subHeading']"></p>
  <p style="margin-top: 13px;" [textContent]="data['subHeading2']"></p>
</div>


<div class="thankTxt tac">
  <button class="successBtn cancel" mat-raised-button (click)="onNoClick()" *ngIf="data['cancelButtonText']"
    [textContent]="data['cancelButtonText']"></button>
  <button class="ml10" [ngClass]="{'warningBtn': data['icon'] == 'error_outline', 'successBtn': data['icon'] == 'check_circle_outline', 'delBtn': data['icon'] == 'delete_outline' }" mat-raised-button (click)="onYesClick()" *ngIf="data['okButtonText']"
    [textContent]="data['okButtonText']"></button>
</div>