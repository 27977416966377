<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/db-logo.png" alt="" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/db-logo.png" alt="" />
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/db-logo.png" alt="" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/db-logo.png" alt="" />
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item mobile-content"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ml-2" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          id="page-header-search-dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          ngbDropdownToggle
          aria-expanded="false"
        >
          <i class="mdi mdi-magnify"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
          ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown"
        ></div>
      </div>
      <div class="dropdown d-inline-block" ngbDropdown *ngIf="userdet.user_type == 'admin'">
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img src="{{ defaultcountry }}" alt="Country" height="33" />
          <span class="ml-1">{{ countryName }}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <a
            href="javascript:void(0);"
            (click)="switchCountry('1', 'assets/images/flags/india.png', 'India')"
            class="dropdown-item notify-item"
          >
            <img src="assets/images/flags/india.png" alt="user-image" class="mr-1" height="25" />
            <span class="align-middle">India</span>
          </a>
          <a
            href="javascript:void(0);"
            (click)="switchCountry('2', 'assets/images/flags/panama.png', 'Panama')"
            class="dropdown-item notify-item"
          >
            <img src="assets/images/flags/panama.png" alt="user-image" class="mr-1" height="25" />
            <span class="align-middle">Panama</span>
          </a>
        </div>
      </div>
      <!-- {{'Name' | translate}} -->

      <div class="dropdown d-inline-block" ngbDropdown *ngIf="userdet.user_type != 'admin'">
        <div class="lang" ngbDropdownToggle>
          <button type="button" class="btn header-item" id="page-header-user-dropdown">
            <img class="" src="{{ defaultFlag }}" alt="Header Language" height="33" />
            <span class="ml-1">{{ languageName }}</span>
          </button>
        </div>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <a
            href="javascript:void(0);"
            (click)="switchLang('en', 'assets/images/flags/india.png')"
            class="dropdown-item notify-item"
          >
            <img src="assets/images/flags/india.png" alt="user-image" class="mr-1" height="25" width="35" />
            <span class="align-middle">English</span>
          </a>
          <a
            href="javascript:void(0);"
            (click)="switchLang('es', 'assets/images/flags/panama.png')"
            class="dropdown-item notify-item"
          >
            <img src="assets/images/flags/panama.png" alt="user-image" class="mr-1" height="25" width="35" />
            <span class="align-middle">Spanish</span>
          </a>
        </div>
      </div>

      <!-- *************************************** change country dropdown ************************************ -->

      <div class="dropdown d-inline-block" ngbDropdown *ngIf="userdet.user_type != 'admin'">
        <div class="country" ngbDropdownToggle>
          <button type="button" class="btn header-item" id="page-header-user-dropdown">
            <img class="" src="{{ defaultCountryFlag }}" alt="Country" height="33" />
            <span class="ml-1">{{ countryName }}</span>
          </button>
        </div>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <a
            *ngIf="country_id == 1 || indiaMarketPlace"
            href="javascript:void(0);"
            (click)="switchCountry('1', 'assets/images/flags/india.png', 'India')"
            class="dropdown-item notify-item"
          >
            <img src="assets/images/flags/india.png" alt="user-image" class="mr-1" height="25" />
            <span class="align-middle">India</span>
          </a>
          <a
            *ngIf="country_id == 2 || panamaMarketPlace"
            href="javascript:void(0);"
            (click)="switchCountry('2', 'assets/images/flags/panama.png', 'Panama')"
            class="dropdown-item notify-item"
          >
            <img src="assets/images/flags/panama.png" alt="user-image" class="mr-1" height="25" />
            <span class="align-middle">Panama</span>
          </a>
        </div>
      </div>

      <!-- *************************************** change country  end************************************ -->

      <!-- <div class="dropdown d-none d-lg-inline-block ml-1" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" ngbDropdownToggle>
          <i class="bx bx-customize"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" ngbDropdownMenu>
          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/github.png" alt="Github">
                  <span>GitHub</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/bitbucket.png" alt="bitbucket">
                  <span>Bitbucket</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dribbble.png" alt="dribbble">
                  <span>Dribbble</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dropbox.png" alt="dropbox">
                  <span>Dropbox</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">
                  <span>Mail Chimp</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/slack.png" alt="slack">
                  <span>Slack</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="dropdown d-none d-lg-inline-block ml-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <!-- <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge badge-danger badge-pill">3</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0"> Notifications </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small"> View All</a>
              </div>
            </div>
          </div>
          <perfect-scrollbar style="position: relative; height: 230px;" [config]="configData">
            <a href="" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">Your order is placed</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">If several languages coalesce the grammar</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 3 min ago</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="media">
                <img src="assets/images/users/avatar-3.jpg" class="mr-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">James Lemire</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">It will seem like simplified English.</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 1 hours ago</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-success rounded-circle font-size-16">
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">Your item is shipped</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">If several languages coalesce the grammar</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 3 min ago</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="media">
                <img src="assets/images/users/avatar-4.jpg" class="mr-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">Salena Layfield</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">As a skeptical Cambridge friend of mine occidental.</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 1 hours ago</p>
                  </div>
                </div>
              </div>
            </a>
          </perfect-scrollbar>
          <div class="p-2 border-top">
            <a class="btn btn-sm btn-light btn-block text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-down-circle mr-1"></i> Load More..
            </a>
          </div>
        </div>
      </div> -->

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <!-- <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar"> -->
          {{ userdet.first_name }} {{ userdet.last_name }}
          <!-- <span class="d-none d-xl-inline-block ml-1">{{userdet.first_name}} {{userdet.last_name}}</span> -->
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>

        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <!-- item-->
          <!-- <a class="dropdown-item" href="javascript: void(0);"><i class="bx bx-user font-size-16 align-middle mr-1"></i>
            Profile</a>
          <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-wallet font-size-16 align-middle mr-1"></i> My Wallet</a>
          <a class="dropdown-item d-block" href="javascript: void(0);"><span
              class="badge badge-success float-right">11</span><i
              class="bx bx-wrench font-size-16 align-middle mr-1"></i> Settings</a> -->
          <a class="dropdown-item" href="javascript: void(0);" (click)="change_password()"
            ><i class="bx bx-lock-open font-size-16 align-middle mr-1"></i>{{ 'Change Password' | translate }}</a
          >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"
            ><i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>{{ 'Logout' | translate }}</a
          >
        </div>
      </div>
      <div class="d-inline loading">
        <div class="spinner-border text-primary m-1" *ngIf="loading" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div> -->
    </div>
  </div>
</header>
