import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

// custom validator to check that two fields match
export const ConfirmPassword: ValidatorFn = (form: FormGroup): ValidationErrors | null => {
    const password = form.controls.password.value;
    let confirmPassword;
    if (form.controls.confirm_password) {
        confirmPassword = form.controls.confirm_password.value
    }
    else {
        confirmPassword = form.controls.password_confirmation.value;
    }
   
    if (password === confirmPassword || confirmPassword == '') {
        return null;
    }
    else {
        return { 'passwordMisMatch': true };
    }
}
