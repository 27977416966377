import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  event: any;
  croppedImage: any;
}

export interface ImageCroppedEvent {
  base64?: string | null;
  file?: Blob | null;
  width: number;
  height: number;
}

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-croper.component.html',
  styleUrls: ['./image-croper.component.scss']
})
export class ImgCropperComponent implements OnInit {

  croppedImage: any = '';
  isLoading: Boolean = false;
  // image: any;
  isLoginUser;

  constructor(
    public dialogRef: MatDialogRef<ImgCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close({ option: 2, data: this.data });
  }
  close(): void {
    this.dialogRef.close({ option: 2, data: this.data });
  }
  imageCropped(event: ImageCroppedEvent) {
    this.data.croppedImage = event;
  }

  uploadImage() {
    this.data['image'] = this.base64ToFile(this.data.croppedImage.base64, this.data.event.target.files[0].name)
    this.dialogRef.close({ option: 1, data: this.data });
  }
 
  imageLoaded() {
    // show cropper
    this.isLoading = false;
  }

  loadImageFailed() {
    // show message
  }

  ngOnInit() {
    this.isLoading = true;
  }

  //convert to base64
  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
}
