import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-ts';
import { environment } from '@env/environment'

@Injectable({
  providedIn: 'root'
})

export class CryptoService {

  constructor() { }

  getKey() {
    return environment.request_encode_key;
  }

  encode(data: any) {
      const encryptData = JSON.stringify(data);
      const encryptedMessage = AES.encrypt(encryptData, this.getKey()).toString();
      return encryptedMessage;
  }


  decode(data: any) {
      if (!data || data == null) {
        return data;
      }
      const bytes = AES.decrypt(data.toString(), this.getKey());
      const decryptedData = bytes.toString(enc.Utf8);
      if (decryptedData) {
        return JSON.parse(decryptedData);
      } else {
        return false;
      }
    }
}