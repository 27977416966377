<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <div *ngIf="userdet.user_type == 'admin'">
        <ng-container *ngFor="let item of menuItems">
          <li class="menu-title" *ngIf="item.isTitle && (item.production || is_staging)">
            {{ item.label }}
          </li>
          <!-- Layouts menu -->
          <li *ngIf="item.isLayout && (item.production || is_staging)">
            <a href="javascript: void(0);" class="has-arrow">
              <i class="bx bx-layout"></i>
              <span>Layouts</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li>
                <a (click)="changeLayout('horizontal')" href="javascript: void(0);">Horizontal</a>
              </li>
              <li>
                <a (click)="lightSidebar()" href="javascript: void(0);">Light Sidebar</a>
              </li>
              <li>
                <a (click)="compactSidebar()" href="javascript: void(0);">Compact Sidebar</a>
              </li>
              <li>
                <a (click)="iconSidebar()" href="javascript: void(0);">Icon Sidebar</a>
              </li>
              <li>
                <a (click)="boxedLayout()" href="javascript: void(0);">Boxed Layout</a>
              </li>
              <li>
                <a (click)="coloredSidebar()" href="javascript: void(0);">Colored Sidebar</a>
              </li>
            </ul>
          </li>
          <!-- end Layout menu -->

          <li *ngIf="!item.isTitle && !item.isLayout && (item.production || is_staging)">
            <a
              *ngIf="hasItems(item)"
              href="javascript:void(0);"
              class="is-parent"
              [ngClass]="{
                'has-arrow': !item.badge,
                'has-dropdown': item.badge
              }"
            >
              <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
              <span> {{ item.label | titlecase }}</span>
              <span class="badge badge-pill badge-{{ item.badge.variant }} float-right" *ngIf="item.badge">{{
                item.badge.text
              }}</span>
            </a>

            <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
              <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
              <span> {{ item.label | titlecase }}</span>
              <span class="badge badge-pill badge-{{ item.badge.variant }} float-right" *ngIf="item.badge">{{
                item.badge.text
              }}</span>
            </a>

            <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
              <li *ngFor="let subitem of item.subItems">
                <a
                  [routerLink]="subitem.link"
                  *ngIf="!hasItems(subitem)"
                  class="side-nav-link-ref"
                  [attr.data-parent]="subitem.parentId"
                  routerLinkActive="active"
                >
                  {{ subitem.label }}
                </a>
                <a
                  *ngIf="hasItems(subitem)"
                  class="side-nav-link-a-ref has-arrow"
                  href="javascript:void(0);"
                  [attr.data-parent]="subitem.parentId"
                >
                  {{ subitem.label }}
                </a>
                <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                  <li *ngFor="let subSubitem of subitem.subItems">
                    <a
                      [attr.data-parent]="subSubitem.parentId"
                      [routerLink]="subSubitem.link"
                      routerLinkActive="active"
                      class="side-nav-link-ref"
                    >
                      {{ subSubitem.label }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ng-container>
      </div>
      <div *ngIf="userdet.user_type != 'admin'">
        <ng-container *ngFor="let item of menuItems">
          <div *ngFor="let role of userdet.roles">
            <div *ngIf="role.name == item.label && role.is_checked">
              <li class="menu-title" *ngIf="item.isTitle">
                {{ item.label | translate | titlecase }}
              </li>
              <li *ngIf="!item.isTitle && !item.isLayout">
                <a
                  *ngIf="hasItems(item)"
                  href="javascript:void(0);"
                  class="is-parent"
                  [ngClass]="{
                    'has-arrow': !item.badge,
                    'has-dropdown': item.badge
                  }"
                >
                  <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                  <span> {{ item.label | translate | titlecase }}</span>
                  <span class="badge badge-pill badge-{{ item.badge.variant }} float-right" *ngIf="item.badge">{{
                    item.badge.text
                  }}</span>
                </a>

                <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                  <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                  <span> {{ item.label | translate }}</span>
                  <span class="badge badge-pill badge-{{ item.badge.variant }} float-right" *ngIf="item.badge">{{
                    item.badge.text
                  }}</span>
                </a>
                <!-- ####################### sub menu item ############################# -->
                <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                  <div *ngFor="let roleper of role.permission">
                    <div *ngFor="let subitem of item.subItems">
                      <li *ngIf="roleper.name == subitem.Permission && roleper.is_checked">
                        <a
                          [routerLink]="subitem.link"
                          *ngIf="!hasItems(subitem)"
                          class="side-nav-link-ref"
                          [attr.data-parent]="subitem.parentId"
                          routerLinkActive="active"
                        >
                          {{ subitem.label | translate }}
                        </a>
                        <a
                          *ngIf="hasItems(subitem)"
                          class="side-nav-link-a-ref has-arrow"
                          href="javascript:void(0);"
                          [attr.data-parent]="subitem.parentId"
                        >
                          {{ subitem.label | translate }}
                        </a>
                        <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                          <li *ngFor="let subSubitem of subitem.subItems">
                            <a
                              [attr.data-parent]="subSubitem.parentId"
                              [routerLink]="subSubitem.link"
                              routerLinkActive="active"
                              class="side-nav-link-ref"
                            >
                              {{ subSubitem.label | translate }}
                            </a>
                          </li>
                        </ul>
                      </li>
                    </div>
                  </div>
                </ul>
              </li>
            </div>
          </div>
        </ng-container>
      </div>
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <perfect-scrollbar [config]="configData">
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </perfect-scrollbar>
</div>
<!-- Left Sidebar End -->
