/** @format */

import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
	{
		id: 1,
		label: 'Menu',
		isTitle: true,
	},
	{
		id: 2,
		label: 'Dashboard',
		icon: 'bx-home-circle',
		link: '/dashboard',
		production: true,
		/*badge: {
            variant: 'info',
            text: '03',
        },*/
		subItems: [
			/*{
                id: 3,
                label: 'Default',
                link: '/dashboard',
                parentId: 2,
                Permission:'VIEW'
            },*/
			/* {
                id: 4,
                label: 'Saas',
                link: '/dashboards/saas',
                Permission:'VIEW',
                parentId: 2
            },
            {
                id: 5,
                label: 'Crypto',
                link: '/dashboards/crypto',
                Permission:'VIEW',
                parentId: 2
            },*/
		],
	},
	{
		id: 1100,
		label: 'Account Info',
		icon: 'bx-user',
		link: '/vendor/account-info',
		production: false,
		/*badge: {
            variant: 'info',
            text: '03',
        },*/
		subItems: [
			/*{
                id: 3,
                label: 'Default',
                link: '/dashboard',
                parentId: 2,
                Permission:'VIEW'
            },*/
			/* {
                id: 4,
                label: 'Saas',
                link: '/dashboards/saas',
                Permission:'VIEW',
                parentId: 2
            },
            {
                id: 5,
                label: 'Crypto',
                link: '/dashboards/crypto',
                Permission:'VIEW',
                parentId: 2
            },*/
		],
	},
	{
		id: 6,
		production: false,
		isLayout: true,
	},
	{
		id: 7,
		label: 'Apps',
		isTitle: true,
		production: false,
	},
	{
		id: 8,
		label: 'Role management',
		icon: 'bx bxs-door-open',
		production: true,
		subItems: [
			{
				id: 9,
				label: 'Role List',
				Permission: 'VIEW',
				sublink: null,
				link: '/role-management/role-list',
				parentId: 8,
			},
			{
				id: 10,
				label: 'Create Role',
				Permission: 'ADD',
				sublink: '/role-management//edit-role',
				link: '/role-management/create-role',
				parentId: 8,
			},
		],
	},
	{
		id: 11,
		label: 'User Management',
		icon: 'bx bxs-user-rectangle',
		production: true,
		subItems: [
			{
				id: 11,
				Permission: 'VIEW',
				label: 'Customer List',
				link: '/user-management/user-list',
				sublink: null,
				parentId: 10,
			},
			{
				id: 11,
				Permission: 'VIEW',
				label: 'Vendors List',
				sublink: null,
				link: '/user-management/supplier-list',
				parentId: 10,
			},
		],
	},
	{
		id: 12,
		label: 'Product',
		icon: 'bx bxs-shopping-bags',
		production: true,
		subItems: [
			{
				id: 13,
				label: 'Product List',
				Permission: 'VIEW',
				link: '/product/product-List',
				sublink: null,
				parentId: 12,
			},
			{
				id: 14,
				label: 'Add Product',
				Permission: 'ADD',
				link: '/product/add-products',
				sublink: null,
				parentId: 12,
			},
			{
				id: 1556,
				label: 'Bulk Product Upload',
				Permission: 'ADD',
				link: '/product/bulk-upload',
				sublink: null,
				parentId: 12,
			},
		],
	},
	{
		id: 106,
		label: 'Product Attribute',
		icon: 'bx bxs-zap',
		production: true,
		subItems: [
			{
				id: 107,
				label: 'Attribute List',
				Permission: 'VIEW',
				link: '/attribute/attribute-list',
				sublink: null,
				parentId: 105,
			},
			{
				id: 108,
				label: 'Add Attribute',
				Permission: 'ADD',
				sublink: null,
				link: '/attribute/add-attribute',
				parentId: 106,
			},
		],
	},
	{
		id: 15,
		label: 'Store',
		production: true,
		icon: 'bx-store',
		subItems: [
			{
				id: 16,
				label: 'Store List',
				Permission: 'VIEW',
				link: '/store/store-list',
				sublink: null,
				parentId: 15,
			},
			{
				id: 17,
				label: 'Add Store',
				Permission: 'ADD',
				link: '/store/add-store',
				sublink: null,
				parentId: 15,
			},
		],
	},
	{
		id: 18,
		label: 'Staff Member',
		production: true,
		icon: 'bx bxs-user-rectangle',
		subItems: [
			{
				id: 19,
				label: 'Staff-Member List',
				Permission: 'VIEW',
				link: '/staff-member/staff-member-list',
				sublink: null,
				parentId: 18,
			},
			{
				id: 20,
				label: 'Add Staff-Member',
				Permission: 'ADD',
				sublink: null,
				link: '/staff-member/add-staff-member',
				parentId: 18,
			},
		],
	},
	{
		id: 9,
		label: 'Category',
		production: true,
		icon: 'bx-usb',
		subItems: [
			{
				id: 10,
				label: 'Add Category',
				Permission: 'VIEW',
				link: '/category/add-category',
				sublink: null,
				parentId: 9,
			},
			{
				id: 11,
				label: 'Category List',
				Permission: 'ADD',
				link: '/category/category-list',
				sublink: null,
				parentId: 9,
			},
		],
	},
	{
		id: 107,
		label: 'Promo Code',
		production: true,
		icon: 'bx-barcode',
		subItems: [
			{
				id: 108,
				label: 'Add Promo Code',
				Permission: 'ADD',
				link: '/promocode/add-promocode',
				sublink: null,
				parentId: 107,
			},
			{
				id: 109,
				label: 'Promo Code List',
				Permission: 'VIEW',
				link: '/promocode/promocode-list',
				sublink: null,
				parentId: 107,
			},
		],
	},
	{
		id: 9,
		label: 'Sub Admin',
		production: true,
		icon: 'bx bxs-user-rectangle',
		subItems: [
			{
				id: 10,
				label: 'Add Sub Admin',
				Permission: 'ADD',
				link: '/subadmin/add-subadmin',
				sublink: null,
				parentId: 9,
			},
			{
				id: 11,
				label: 'Sub Admin List',
				Permission: 'VIEW',
				link: '/subadmin/subadmin-list',
				sublink: null,
				parentId: 9,
			},
		],
	},

	{
		id: 1030,
		label: ' CMS Pages',
		production: true,
		icon: 'bx-money',
		subItems: [
			{
				id: 1031,
				label: '  Cms Create',
				Permission: 'ADD',
				link: '/cms/cms-create',
				sublink: null,
				parentId: 1030,
			},
			// {
			// 	id: 1032,
			// 	label: 'Cms List',
			// 	Permission: 'VIEW',
			// 	link: '/cms/cms-list',
			// 	sublink: null,
			// 	parentId: 1030,
			// },
			{
				id: 1032,
				label: 'Cms List',
				Permission: 'VIEW',
				link: '/cms/cms-pages-list',
				sublink: null,
				parentId: 1030,
			},
		],
	},

	{
		id: 200,
		label: 'Category Management',
		production: true,
		icon: 'bx-usb',
		subItems: [
			{
				id: 201,
				label: 'Dropdown Category',
				Permission: 'UPDATE',
				link: '/categorymanagement/dropdown-category',
				sublink: null,
				parentId: 200,
			},
			{
				id: 202,
				label: 'Sidebar Category',
				Permission: 'UPDATE',
				link: '/categorymanagement/sidebar-category',
				sublink: null,
				parentId: 200,
			},
		],
	},
	{
		id: 8,
		label: 'Calendar',
		production: false,
		icon: 'bx-calendar',
		link: '/calendar',
	},
	{
		id: 9,
		label: 'Chat',
		production: false,
		icon: 'bx-chat',
		link: '/chat',
		badge: {
			variant: 'success',
			text: 'New',
		},
	},
	{
		id: 10,
		label: 'Ecommerce',
		production: false,
		icon: 'bx-store',
		subItems: [
			{
				id: 11,
				label: 'Products',
				link: '/ecommerce/products',
				parentId: 10,
			},
			{
				id: 12,
				label: 'Product Detail',
				link: '/ecommerce/product-detail',
				parentId: 10,
			},
			{
				id: 13,
				label: 'Orders',
				link: '/ecommerce/orders',
				parentId: 10,
			},
			{
				id: 14,
				label: 'Customers',
				link: '/ecommerce/customers',
				parentId: 10,
			},
			{
				id: 15,
				label: 'Cart',
				link: '/ecommerce/cart',
				parentId: 10,
			},
			{
				id: 16,
				label: 'Checkout',
				link: '/ecommerce/checkout',
				parentId: 10,
			},
			{
				id: 17,
				label: 'Shops',
				link: '/ecommerce/shops',
				parentId: 10,
			},
			{
				id: 18,
				label: 'Add Product',
				link: '/ecommerce/add-product',
				parentId: 10,
			},
		],
	},
	{
		id: 19,
		label: 'Crypto',
		production: false,
		icon: 'bx-bitcoin',
		subItems: [
			{
				id: 20,
				label: 'Wallet',
				link: '/crypto/wallet',
				parentId: 19,
			},
			{
				id: 21,
				label: 'Buy/Sell',
				link: '/crypto/buy-sell',
				parentId: 19,
			},
			{
				id: 22,
				label: 'Exchange',
				link: '/crypto/exchange',
				parentId: 19,
			},
			{
				id: 23,
				label: 'Lending',
				link: '/crypto/lending',
				parentId: 19,
			},
			{
				id: 24,
				label: 'Orders',
				link: '/crypto/orders',
				parentId: 19,
			},
			{
				id: 25,
				label: 'KYC Application',
				link: '/crypto/kyc-application',
				parentId: 19,
			},
			{
				id: 26,
				label: 'ICO Landing',
				link: '/crypto-ico-landing',
				parentId: 19,
			},
		],
	},
	{
		id: 27,
		label: 'Email',
		production: false,
		icon: 'bx-envelope',
		subItems: [
			{
				id: 28,
				label: 'Inbox',
				link: '/email/inbox',
				parentId: 27,
			},
			{
				id: 29,
				label: 'Read Email',
				link: '/email/read',
				parentId: 27,
			},
		],
	},
	{
		id: 30,
		label: 'Invoices',
		production: false,
		icon: 'bx-receipt',
		subItems: [
			{
				id: 31,
				label: 'Invoice List',
				link: '/invoices/list',
				parentId: 30,
			},
			{
				id: 32,
				label: 'Invoice Detail',
				link: '/invoices/detail',
				parentId: 30,
			},
		],
	},
	{
		id: 33,
		label: 'Projects',
		production: false,
		icon: 'bx-briefcase-alt-2',
		subItems: [
			{
				id: 34,
				label: 'Projects Grid',
				link: '/projects/grid',
				parentId: 33,
			},
			{
				id: 35,
				label: 'Projects List',
				link: '/projects/list',
				parentId: 33,
			},
			{
				id: 36,
				label: 'Project Overview',
				link: '/projects/overview',
				parentId: 33,
			},
			{
				id: 37,
				label: 'Create New',
				link: '/projects/create',
				parentId: 33,
			},
		],
	},
	{
		id: 38,
		label: 'Tasks',
		production: false,
		icon: 'bx-task',
		subItems: [
			{
				id: 39,
				label: 'Task List',
				link: '/tasks/list',
				parentId: 38,
			},
			{
				id: 40,
				label: 'Kanban Board',
				link: '/tasks/kanban',
				parentId: 38,
			},
			{
				id: 41,
				label: 'Create Task',
				link: '/tasks/create',
				parentId: 38,
			},
		],
	},
	{
		id: 42,
		label: 'Contacts',
		production: false,
		icon: 'bxs-user-detail',
		subItems: [
			{
				id: 43,
				label: 'User Grid',
				link: '/contacts/grid',
				parentId: 42,
			},
			{
				id: 44,
				label: 'User List',
				link: '/contacts/list',
				parentId: 42,
			},
			{
				id: 45,
				label: 'Profile',
				link: '/contacts/profile',
				parentId: 42,
			},
		],
	},
	{
		id: 46,
		label: 'Pages',
		production: false,
		isTitle: true,
	},
	{
		id: 47,
		label: 'Authentication',
		production: false,
		icon: 'bx-user-circle',
		subItems: [
			{
				id: 48,
				label: 'Login',
				link: '/pages/login-1',
				parentId: 47,
			},
			{
				id: 49,
				label: 'Register',
				link: '/pages/register-1',
				parentId: 47,
			},
			{
				id: 50,
				label: 'Recover Password',
				link: '/pages/recoverpwd-1',
				parentId: 47,
			},
			{
				id: 51,
				label: 'Lock Screen',
				link: '/pages/lock-screen-1',
				parentId: 47,
			},
		],
	},
	{
		id: 52,
		label: 'Utility',
		production: false,
		icon: 'bx-file',
		subItems: [
			{
				id: 53,
				label: 'Starter Page',
				link: '/pages/starter',
				parentId: 52,
			},
			{
				id: 54,
				label: 'Maintenance',
				link: '/pages/maintenance',
				parentId: 52,
			},
			{
				id: 55,
				label: 'Timeline',
				link: '/pages/timeline',
				parentId: 52,
			},
			{
				id: 56,
				label: 'FAQs',
				link: '/pages/faqs',
				parentId: 52,
			},
			{
				id: 57,
				label: 'Pricing',
				link: '/pages/pricing',
				parentId: 52,
			},
			{
				id: 58,
				label: 'Error 404',
				link: '/pages/404',
				parentId: 52,
			},
			{
				id: 59,
				label: 'Error 500',
				link: '/pages/500',
				parentId: 52,
			},
		],
	},
	{
		id: 60,
		production: false,
		label: 'Components',
		isTitle: true,
	},
	{
		id: 61,
		label: 'UI Elements',
		production: false,
		icon: 'bx-tone',
		subItems: [
			{
				id: 62,
				label: 'Alerts',
				link: '/ui/alerts',
				parentId: 61,
			},
			{
				id: 63,
				label: 'Buttons',
				link: '/ui/buttons',
				parentId: 61,
			},
			{
				id: 64,
				label: 'Cards',
				link: '/ui/cards',
				parentId: 61,
			},
			{
				id: 65,
				label: 'Carousel',
				link: '/ui/carousel',
				parentId: 61,
			},
			{
				id: 66,
				label: 'Dropdowns',
				link: '/ui/dropdowns',
				parentId: 61,
			},
			{
				id: 67,
				label: 'Grid',
				link: '/ui/grid',
				parentId: 61,
			},
			{
				id: 68,
				label: 'Images',
				link: '/ui/images',
				parentId: 61,
			},
			{
				id: 69,
				label: 'Modals',
				link: '/ui/modals',
				parentId: 61,
			},
			{
				id: 70,
				label: 'Range Slider',
				link: '/ui/rangeslider',
				parentId: 61,
			},
			{
				id: 71,
				label: 'Progress Bars',
				link: '/ui/progressbar',
				parentId: 61,
			},
			{
				id: 72,
				label: 'Sweet-Alert',
				link: '/ui/sweet-alert',
				parentId: 61,
			},
			{
				id: 73,
				label: 'Tabs & Accordions',
				link: '/ui/tabs-accordions',
				parentId: 61,
			},
			{
				id: 74,
				label: 'Typography',
				link: '/ui/typography',
				parentId: 61,
			},
			{
				id: 75,
				label: 'Video',
				link: '/ui/video',
				parentId: 61,
			},
			{
				id: 76,
				label: 'General',
				link: '/ui/general',
				parentId: 61,
			},
			{
				id: 77,
				label: 'Colors',
				link: '/ui/colors',
				parentId: 61,
			},
			{
				id: 78,
				label: 'Image Cropper',
				link: '/ui/image-crop',
				parentId: 61,
			},
		],
	},
	{
		id: 79,
		label: 'Forms',
		production: false,
		icon: 'bxs-eraser',
		subItems: [
			{
				id: 80,
				label: 'Form Elements',
				link: '/form/elements',
				parentId: 79,
			},
			{
				id: 81,
				label: 'Form Validation',
				link: '/form/validation',
				parentId: 79,
			},
			{
				id: 82,
				label: 'Form Advanced',
				link: '/form/advanced',
				parentId: 79,
			},
			{
				id: 83,
				label: 'Form Editors',
				link: '/form/editor',
				parentId: 79,
			},
			{
				id: 84,
				label: 'Form File Upload',
				link: '/form/uploads',
				parentId: 79,
			},
			{
				id: 85,
				label: 'Form Repeater',
				link: '/form/repeater',
				parentId: 79,
			},
			{
				id: 86,
				label: 'Form Wizard',
				link: '/form/wizard',
				parentId: 79,
			},
			{
				id: 87,
				label: 'Form Mask',
				link: '/form/mask',
				parentId: 79,
			},
		],
	},
	{
		id: 88,
		icon: 'bx-list-ul',
		label: 'Tables',
		production: false,
		subItems: [
			{
				id: 89,
				label: 'Basic Tables',
				link: '/tables/basic',
				parentId: 88,
			},
			{
				id: 90,
				label: 'Advanced Table',
				link: '/tables/advanced',
				parentId: 88,
			},
		],
	},
	{
		id: 91,
		icon: 'bxs-bar-chart-alt-2',
		production: false,
		label: 'Charts',
		subItems: [
			{
				id: 92,
				label: 'Apex charts',
				link: '/charts/apex',
				parentId: 91,
			},
			{
				id: 93,
				label: 'Chartjs Chart',
				link: '/charts/chartjs',
				parentId: 91,
			},
			{
				id: 94,
				label: 'Chartist Chart',
				link: '/charts/chartist',
				parentId: 91,
			},
			{
				id: 95,
				label: 'E - Chart',
				link: '/charts/echart',
				parentId: 91,
			},
		],
	},
	{
		id: 96,
		label: 'Icons',
		production: false,
		icon: 'bx-aperture',
		subItems: [
			{
				id: 97,
				label: 'Boxicons',
				link: '/icons/boxicons',
				parentId: 96,
			},
			{
				id: 98,
				label: 'Material Design',
				link: '/icons/materialdesign',
				parentId: 96,
			},
			{
				id: 99,
				label: 'Dripicons',
				link: '/icons/dripicons',
				parentId: 96,
			},
			{
				id: 100,
				label: 'Font awesome',
				link: '/icons/fontawesome',
				parentId: 96,
			},
		],
	},
	{
		id: 101,
		label: 'Maps',
		production: false,
		icon: 'bx-map',
		subItems: [
			{
				id: 102,
				label: 'Google Maps',
				link: '/maps/google',
				parentId: 101,
			},
		],
	},
	{
		id: 103,
		label: 'Multi Level',
		production: false,
		icon: 'bx-share-alt',
		subItems: [
			{
				id: 104,
				label: 'Level 1.1',
				link: 'javascript: void(0);',
				parentId: 103,
			},
			{
				id: 105,
				label: 'Level 1.2',
				parentId: 103,
				subItems: [
					{
						id: 106,
						label: 'Level 2.1',
						link: 'javascript: void(0);',
						parentId: 105,
					},
					{
						id: 107,
						label: 'Level 2.2',
						link: 'javascript: void(0);',
						parentId: 105,
					},
				],
			},
		],
	},
	{
		id: 1001,
		label: 'Order Management',
		production: true,
		icon: 'bx-store',
		subItems: [
			{
				id: 1002,
				label: 'Orders List',
				Permission: 'VIEW',
				link: '/order-management/orders',
				sublink: null,
				parentId: 1001,
			},
			// {
			//   id: 1002,
			//   label: 'Test order',
			//   Permission: 'VIEW',
			//   link: '/order-management/order-details',
			//   sublink: null,
			//   parentId: 1001,
			// },
		],
	},
	// {
	//   id: 1004,
	//   label: 'Report',
	//   production: true,
	//   icon: 'bx bx-money',
	//   subItems: [
	//     {
	//       id: 1005,
	//       label: 'Orders Report',
	//       Permission: 'View',
	//       link: '/report/orders-report',
	//       sublink: null,
	//       parentId: 1004,
	//     },
	//     {
	//       id: 1006,
	//       label: 'Sales Report',
	//       Permission: 'VIEW',
	//       link: '/report/sales-report',
	//       sublink: null,
	//       parentId: 1004,
	//     },
	//   ],
	// },

	{
		id: 1007,
		label: 'Payment Methods',
		production: true,
		icon: 'bx-credit-card',
		subItems: [
			{
				id: 1008,
				label: 'Payment Methods List',
				Permission: 'VIEW',
				link: '/payment-methods/savecards',
				sublink: null,
				parentId: 1007,
			},
			{
				id: 1009,
				label: 'Add Payment Method',
				Permission: 'ADD',
				link: '/payment-methods/add-payment-method',
				sublink: null,
				parentId: 1007,
			},
		],
	},
	{
		id: 1010,
		label: 'Subscription',
		production: false,
		icon: 'bx-money',
		subItems: [
			{
				id: 1011,
				label: 'Add Subscription Plan',
				Permission: 'ADD',
				link: '/subscription/add',
				sublink: null,
				parentId: 1010,
			},
			{
				id: 1012,
				label: 'Subscription Plans',
				Permission: 'VIEW',
				link: '/subscription/admin/list',
				sublink: null,
				parentId: 1010,
			},
		],
	},

	{
		id: 1020,
		label: ' Membership Subscriptions',
		production: true,
		icon: 'bx-money',
		subItems: [
			{
				id: 1021,
				label: '  Subscribers List',
				Permission: 'VIEW',
				link: '/subscription/active',
				sublink: null,
				parentId: 1020,
			},
			{
				id: 1022,
				label: 'Subscription Plans',
				Permission: 'VIEW',
				link: '/subscription/list',
				sublink: null,
				parentId: 1020,
			},
		],
	},

	{
		id: 1020,
		label: ' Membership Subscriptions',
		production: true,
		icon: 'bx-money',
		subItems: [
			{
				id: 1021,
				label: '  Subscribers List',
				Permission: 'VIEW',
				link: '/subscription/active',
				sublink: null,
				parentId: 1020,
			},
			{
				id: 1022,
				label: 'Subscription Plans',
				Permission: 'VIEW',
				link: '/subscription/list',
				sublink: null,
				parentId: 1020,
			},
		],
	},
];
