import { Routes } from '@angular/router';
import { AuthGuard } from '../components/core/guards/auth.guard';
import { LogedinAuthGuard } from '@services/auth/logedin-auth-guard.service';
import { LayoutComponent } from '../components/layouts/layout.component';
import { CyptolandingComponent } from '@components/cyptolanding/cyptolanding.component';


export const routes: Routes = [
    {
        path: 'account',
        loadChildren: () => import('@common-components/account/account.module').then(m => m.AccountModule),
        canActivate: [LogedinAuthGuard]
    },
    {
        path: '',
        component: LayoutComponent,
        loadChildren: () => import('@components/pages/pages.module').then(m => m.PagesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'pages',
        loadChildren: () => import('@components/extrapages/extrapages.module').then(m => m.ExtrapagesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'crypto-ico-landing',
        component: CyptolandingComponent
    }
];