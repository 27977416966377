import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { RequestService } from '@services/http/request.service';
import { LOGOUT_API } from '@env/api-path';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '@common-components/pop-up/change-password/change-password.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  element;
  configData;
  userdet: any;
  loading: boolean = false;
  defaultCountryFlag = 'assets/images/flags/india.png'; // for subadmin
  openMobileMenu: boolean;
  defaultFlag: string = 'assets/images/flags/india.png';
  defaultcountry: string = 'assets/images/flags/india.png';
  indiaMarketPlace;
  panamaMarketPlace;
  country_id: any = 1;
  countryName = 'India';
  languageName;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private request: RequestService,
    private auth: AuthService,
    private dialog: MatDialog,
    private router: Router,
    public translate: TranslateService
  ) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.translate.use('en');
    this.auth.setlanguage('en');
    // console.log(this.auth.getUserdata());
    this.languageName = 'English';
    this.userdet = this.auth.getUserdata();
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3,
    };
    if (this.auth.getUserdata().country_id == 2) {
      this.defaultcountry = 'assets/images/flags/panama.png';
      this.country_id = 2;
      this.countryName = 'Panama';
      //for subadmin
      this.defaultCountryFlag = 'assets/images/flags/panama.png';
    }
    this.getMarketPlacePermission();
  }
  switchLang(lang: string, flag: string) {
    if (lang === 'es') {
      this.languageName = 'Spanish';
    }
    if (lang === 'en') {
      this.languageName = 'English';
    }
    this.defaultFlag = flag;
    this.auth.setlanguage(lang);
    this.translate.use(lang);
  }
  switchCountry(id: string, flag: string, countryName: string) {
    if (this.country_id == id) return;
    this.countryName = countryName;
    this.defaultcountry = flag;
    this.defaultCountryFlag = flag;
    this.auth.changeusercountry(id);
    this.country_id = id;
    this.redirectTo();
  }

  getMarketPlacePermission() {
    if (this.userdet.user_type != 'admin') {
      this.indiaMarketPlace = this.auth.checkpermission('Select Marketplace', 'India', this.userdet.roles);
      this.panamaMarketPlace = this.auth.checkpermission('Select Marketplace', 'Panama', this.userdet.roles);
    }
  }

  redirectTo() {
    const url = this.router.url.split('?')[0];
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([url]));
  }
  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.loading = true;
    this.request.GET(LOGOUT_API, {}).subscribe(
      (response) => {
        if (response['status']) {
          this.auth.logOut();
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
        //console.log(err);
      }
    );
  }

  /**
   * Change Password
   */
  change_password() {
    this.dialog.open(ChangePasswordComponent, {
      minWidth: '600px !important',
      // width: '850px',
      // height: '200px',
    });
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (!document.fullscreenElement && !this.element.mozFullScreenElement && !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}
