import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { StorageCookieService } from "../cookie/storage-cookie.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  [x: string]: any;
  constructor(
    private jwtHelper: JwtHelperService,
    private cookie: StorageCookieService
  ) {}

  setUser(token) {
    if (token) {
      this.cookie.setCookie(token.access_token, "USER");
      localStorage.setItem("USER_DATA", JSON.stringify(token));
    }
  }

  isAuthenticated(): boolean {
    //console.log(JSON.parse(this.cookie.getCookie('USER')))
    //const token = JSON.parse(this.cookie.getCookie('USER')).access_token;
    const token = this.cookie.getCookie("USER");
    if (token) {
      return !this.isTokenValid(token);
    }
    return false;
  }

  isTokenValid(token) {
    if (!token) {
      return false;
    }
    return this.jwtHelper.isTokenExpired(token);
  }

  decodeToken(token) {
    if (token) {
      return this.jwtHelper.decodeToken(token);
    }
    return false;
  }

  // setPrivateKey(pkey) {
  //   if (pkey) {
  //     this.cookie.setCookie(pkey, 'autherization');
  //   }
  // }

  getPrivateKey() {
    const userDetail = this.getUser();
    return `&%@)${userDetail["token"]}${userDetail._id}*^$`;
  }

  getUser() {
    const token = this.cookie.getCookie("USER");
    if (token) {
      const userdata = this.jwtHelper.decodeToken(token);

      return userdata["auth"];
    }
    return false;
  }
  getUserdata() {
    const userdata = JSON.parse(localStorage.getItem("USER_DATA"));
    //console.log(userdata,'userdata');
    if (userdata) {
      return userdata;
    }
    return false;
  }
  changeusercountry(country_id) {
    const userdata = JSON.parse(localStorage.getItem("USER_DATA"));
    if (userdata.country_id) {
      userdata.country_id = country_id;
      localStorage.setItem("USER_DATA", JSON.stringify(userdata));
      return true;
    }
    return false;
  }
  checkpermission(module, permission, role) {
    var returnval = false;
    role.forEach((e) => {
      if (e.name == module) {
        e.permission.forEach((per) => {
          if (per.name == permission && per.is_checked) {
            returnval = true;
          }
        });
      }
    });
    return returnval;
  }
  getToken() {
    return this.cookie.getCookie("USER") || null;
  }

  // setStorage(key, data) {
  //   if (data) {
  //     this.cookie.setCookie(data, key);
  //   }
  // }

  getStorage(key) {
    if (key) {
      return this.cookie.getCookie(key);
    } else {
      return false;
    }
  }

  // removeUser() {
  //  this.cookie.removeCookie(['user', 'autherization']);
  //   this.router.navigate(['/']);
  // }

  // setCookie(key, data) {
  //   this.cookie.setCookie(data, key);
  // }

  logOut(): void {
    this.cookie.removeAll();
  }

  getCookie(key) {
    return this.cookie.getCookie(key);
  }

  setCookies(key, data) {
    this.cookie.setCookies(data, key);
  }

  getCookies(key) {
    this.cookie.getCookies(key);
  }
  setlanguage(language) {
    this.cookie.setCookie(language, "USER_LANGUAGE");
    return true;
  }
  getlanguage() {
    const userlan = this.cookie.getCookie("USER_LANGUAGE") ?? false;
    if (userlan) {
      return userlan;
    } else {
      return "en";
    }
  }
}
