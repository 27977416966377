import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthInterceptor } from '@services/interceptors/interceptors.service';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@services/snackbar/snackbar-component/snackbar.component';

import { NgbNavModule, NgbAccordionModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { SharedModule } from './components/cyptolanding/shared/shared.module';
import { ExtrapagesModule } from './components/extrapages/extrapages.module';

import { LayoutsModule } from './components/layouts/layouts.module';
import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app-component/app.component';
import { CyptolandingComponent } from './components/cyptolanding/cyptolanding.component';

import { JwtModule } from '@auth0/angular-jwt';
import { CookieModule } from 'ngx-cookie';
import { ConfirmationPopupComponent } from './common-components/pop-up/confirmation-popup/confirmation-popup.component';
import { ImgCropperComponent } from '@common-components/image-croper/image-croper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserDetailsPopupComponent } from '@components/pages/user-management/user-details-popup/user-details-popup.component';
import { StaffMemberDetailPopupComponent } from '@components/pages/staff-member/staff-member-detail-popup/staff-member-detail-popup.component';
export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    CyptolandingComponent,
    ConfirmationPopupComponent,
    SnackbarComponent,
    ImgCropperComponent,
    UserDetailsPopupComponent,
    StaffMemberDetailPopupComponent,
  ],
  imports: [
    BrowserModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ImageCropperModule,
    LayoutsModule,
    AppRoutingModule,
    ExtrapagesModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ScrollToModule.forRoot(),
    CookieModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['localhost:4200/'],
        blacklistedRoutes: [''],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [SnackbarComponent, ImgCropperComponent, UserDetailsPopupComponent, StaffMemberDetailPopupComponent],
})
export class AppModule {}
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/../..//assets/i18n/', '.json');
}
