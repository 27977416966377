import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
import { TranslateModule } from '@ngx-translate/core';
import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { BackButtonModule } from '@shared/back-button/back-button.module';

@NgModule({
  declarations: [PagetitleComponent],
  imports: [
    CommonModule,
    FormsModule,
    ClickOutsideModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    TranslateModule,
    NgbDropdownModule,
    BackButtonModule,
  ],
  exports: [PagetitleComponent],
})
export class UIModule {}
