import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestService } from '@services/http/request.service';
import { CHANGE_PASSWORD_API } from '@env/api-path';
import { PasswordStrengthValidator } from '@library/password.strength';
import { ConfirmPassword } from '@library/confirm.password';
import { MatDialog } from '@angular/material/dialog';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  _form: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;

  messageType: number = 1;
  message: string = '';

  unsubscribe$ = new SubSink();

  constructor(
    private formBuilder: FormBuilder,
    private request: RequestService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this._form = this.formBuilder.group({
      current_password: ['', Validators.required],
      password: ['', [Validators.required, PasswordStrengthValidator]],
      password_confirmation: ['', Validators.required]
    }, { validators: ConfirmPassword })
  }


  // convenience getter for easy access to form fields
  get f() { return this._form.controls; }

  /**
   * Form submit
   */

  onSubmit() {
    this.submitted = true;

    if (this._form.invalid) {
      return;
    }

    this.loading = true;
    this.unsubscribe$.add(this.request.POST(CHANGE_PASSWORD_API, this._form.value).subscribe(response => {
      this.loading = false;
      if (response['status']) {
        this.messageType = 1;
        this.message = response.message;
        setTimeout(() => {
          this.dialog.closeAll();
        }, 4000)
      }
      else {
        this.messageType = 2;
        this.message = response.message;
      }
    }, err => {
      this.loading = false;
      this.messageType = 2;
      this.message = err.message;
      console.log(err);
    }))
  }

  //cancel
  cancel(): void {
    this.dialog.closeAll();
  }

  // destroy component
  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe()
  }

}
