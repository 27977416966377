<!-- <div class="home">
  <a href="javascript:void(0)" class="text-dark"
    ><i class="fas fa-home h2"></i
  ></a>
</div> -->
<div class="account-pages mt-5 mb-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Change Password!</h5>
                  <p>Change-Password with Pantheia.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="assets/images/profile-img.png" alt="" class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <a href="javascript:void(0)">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34" />
                  </span>
                </div>
              </a>
            </div>
            <div class="p-2">
              <form class="form-horizontal" [formGroup]="_form" (ngSubmit)="onSubmit()">
                <ngb-alert
                  [type]="messageType == 1 ? 'success' : 'danger'"
                  *ngIf="message"
                  [textContent]="message"
                  [dismissible]="false"
                ></ngb-alert>

                <div class="form-group mb-3 position-relative">
                  <label for="current_password">Current Password</label>

                  <input
                    type="password"
                    formControlName="current_password"
                    class="form-control"
                    id="current_password"
                    [ngClass]="{
                      'is-invalid': (submitted || f.current_password.touched) && f.current_password.errors
                    }"
                    placeholder="Current password"
                  />

                  <div
                    *ngIf="(submitted || f.current_password.touched) && f.current_password.errors"
                    class="invalid-tooltip"
                  >
                    <div *ngIf="f.current_password.errors?.required">Current Password is required</div>
                  </div>
                </div>

                <div class="form-group mb-3 position-relative">
                  <label for="password">New Password</label>

                  <input
                    type="password"
                    formControlName="password"
                    class="form-control"
                    id="password"
                    [ngClass]="{
                      'is-invalid': (submitted || f.password.touched) && f.password.errors
                    }"
                    placeholder="New password"
                  />
                  <div *ngIf="(submitted || f.password.touched) && f.password.errors" class="invalid-tooltip">
                    <div *ngIf="f.password.errors.required">New Password is required</div>
                    <div *ngIf="f.password.errors.passwordStrength">
                      Your password must be a minimum of eight characters long and contain a minimum of the following;
                      one small letter, one number and one special character.
                    </div>
                  </div>
                </div>

                <div class="form-group mb-3 position-relative">
                  <label for="confirm_password">Confirm Password</label>

                  <input
                    type="password"
                    formControlName="password_confirmation"
                    class="form-control"
                    id="password_confirmation"
                    [ngClass]="{
                      'is-invalid':
                        ((submitted || f.password_confirmation.touched) && f.password_confirmation.errors) ||
                        _form.errors?.passwordMisMatch
                    }"
                    placeholder="Confirm password"
                  />

                  <div
                    *ngIf="(submitted || f.password_confirmation.touched) && f.password_confirmation.errors"
                    class="invalid-tooltip"
                  >
                    <div *ngIf="f.password_confirmation.errors?.required">Confirm Password is required</div>
                  </div>
                  <div class="invalid-tooltip" *ngIf="_form.errors?.passwordMisMatch">
                    New Password and Confirm Password must be match.
                  </div>
                </div>

                <!-- <div class="mt-3">
                                    <button class="btn btn-primary btn-block"
                                        type="submit">{{loading ? 'Please wait....' : 'Reset'}}</button>
                                </div> -->

                <div class="row justify-content-end text-right">
                  <div class="col-lg-10">
                    <button class="btn btn-primary mr-1" type="submit" [disabled]="loading">
                      <i class="bx bx-loader bx-spin font-size-16 align-middle mr-2" *ngIf="loading"></i>Change Password
                    </button>
                    <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- end container -->
</div>
