<div class="title-container">
  <div class="header">{{ 'Staff Member Details' | translate }}</div>
  <svg (click)="close()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="svg-icon">
    <path
      fill="#000"
      fill-rule="evenodd"
      d="M9.031 8l6.756-6.756a.731.731 0 0 0 0-1.031.732.732 0 0 0-1.031 0L8 6.969 1.244.213a.732.732 0 0 0-1.031 0 .731.731 0 0 0 0 1.03L6.969 8 .213 14.756a.731.731 0 0 0 0 1.031.732.732 0 0 0 1.031 0L8 9.031l6.756 6.756a.732.732 0 0 0 1.031 0 .731.731 0 0 0 0-1.03L9.031 8z"
    ></path>
  </svg>

  <div class="modal-container">
    <div class="modal-body">
      <div class="center__img">
        <img width="80px" height="80px" [src]="imgUrl" (error)="onImgError($event)" />
      </div>
      <div class="row row-container">
        <div class="col-sm-6">
          <span class="left">{{ 'Member Name' | translate }}</span>
        </div>
        <div class="col-sm-6">{{ data.first_name | titlecase }} {{ data.last_name | titlecase }}</div>
      </div>
      <div class="row row-container">
        <div class="col-sm-6">
          <span class="left">{{ 'Affiliated Stores' | translate }}</span>
        </div>
        <div class="col-sm-6">
          <span *ngFor="let name of data.store_name; let i = index">
            {{ name }}
            <span *ngIf="!(i + 1 == data.store_name.length)">, </span>
          </span>
        </div>
      </div>
      <div class="row row-container">
        <div class="col-sm-6">
          <span class="left">{{ 'Employee Position' | translate }}</span>
        </div>
        <div class="col-sm-6">{{ data.employee_position_name }}</div>
      </div>
      <div class="row row-container">
        <div class="col-sm-6">
          <span class="left">{{ 'Email' | translate }}</span>
        </div>
        <div class="col-sm-6">{{ data.email }}</div>
      </div>
      <div class="row row-container">
        <div class="col-sm-6">
          <span class="left">{{ 'Phone Number' | translate }}</span>
        </div>
        <div class="col-sm-6">
          <span *ngIf="data.mobile"> {{ data.calling_code }}- </span>{{ data.mobile }}
        </div>
      </div>
      <div class="row row-container">
        <div class="col-sm-6">
          <span class="left">{{ 'Gender' | translate }}</span>
        </div>
        <div class="col-sm-6">{{ data.gender | titlecase }}</div>
      </div>
      <div class="row row-container">
        <div class="col-sm-6">
          <span class="left">{{ 'Date of Birth' | translate }}</span>
        </div>
        <div class="col-sm-6">{{ data.dob | date: 'longDate' }}</div>
      </div>
      <div class="row row-container">
        <div class="col-sm-6">
          <span class="left">{{ 'Role Name' | translate }}</span>
        </div>
        <div class="col-sm-6">{{ data.role_name }}</div>
      </div>
      <div class="row row-container">
        <div class="col-sm-6">
          <span class="left">{{ 'Created By' | translate }}</span>
        </div>
        <div class="col-sm-6">{{ data.created_by_email }}</div>
      </div>
      <div class="row row-container">
        <div class="col-sm-6">
          <span class="left">{{ 'Created On' | translate }}</span>
        </div>
        <div class="col-sm-6">{{ data.created_at | date: 'medium' }}</div>
      </div>
      <div class="row mt-5">
        <div class="col-12"></div>
      </div>
    </div>
    <div class="modal-footer">
      <div (click)="close()" class="btn btn-primary">Close</div>
    </div>
  </div>
</div>
