import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class LogedinAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private auth: AuthService
        ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

      if (this.auth.isAuthenticated()) {

            // logged in so return true
            this.router.navigate(['/dashboard']);
            return false;
        }

        // not logged in so redirect to login page with the return url
        // this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
       
        return true;
    }
}