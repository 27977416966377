import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthService } from '@services/auth/auth.service';
import { environment } from '@env/environment';
import { unAuthorizedUrl } from '@route/route-urls';
import { tap } from 'rxjs/operators';


@Injectable()

export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    var country_id=1;
    const authToken = this.auth.getToken()
    if(this.auth.getUserdata().country_id){
       country_id= this.auth.getUserdata().country_id;
    }
    let unAutherizedPath = unAuthorizedUrl;
    let api_name = '';
    unAutherizedPath = unAutherizedPath.filter(obj => {
      api_name = obj;
      let url = req.url.split('?');
      let reqUrl = req.url;
      if (url && url.length > 0) {
        reqUrl = url[0];
      }
      return environment.api_url + obj === reqUrl;
    });

    if (unAutherizedPath && unAutherizedPath.length <= 0) {
        const request = req.clone({ setHeaders: { Authorization: `Bearer ${authToken}`,'country-id':`${country_id}`} })
        return next.handle(request);
    }

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          return event;
        }
      }, error => {
        let errorObj = error;
        return errorObj
      }))
  }
}
