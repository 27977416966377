import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@common-components/image-croper/image-croper.component';

@Component({
  selector: 'app-user-details-popup',
  templateUrl: './user-details-popup.component.html',
  styleUrls: ['./user-details-popup.component.scss'],
})
export class UserDetailsPopupComponent implements OnInit {
  data;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data2: DialogData,
    private dialog: MatDialogRef<UserDetailsPopupComponent>
  ) {}

  ngOnInit(): void {
    this.data = this.data2;
    console.log(this.data);
  }

  close() {
    this.dialog.close();
  }
}
