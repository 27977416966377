<div style="margin: -18px -14px -14px -14px;">
    <div>
      <h2>Crop Your Profile Picture</h2>
      <div class="image-cropper-section">
        <div class="img-crop-box">
          <image-cropper [imageChangedEvent]="data.event" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
            cropperMinWidth="200" roundCropper="true" [resizeToWidth]="200" format="png,jpeg"
            (imageCropped)="imageCropped($event)" outputType="both" (imageLoaded)="imageLoaded()"
            (loadImageFailed)="loadImageFailed()"></image-cropper>
  
        </div>
          <div class="croper-button">
          <button mat-raised-button type="submit" mat-dialog-close style="margin-right:10px;" class="btn btn-cancel" (click)="close();">Cancel</button>
          <button mat-raised-button type="submit" class="btn btn-danger" (click)="uploadImage()">Set as profile photo</button>
        </div>
      </div>
    </div>
  </div>