/** @format */

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,
	staging: false,
	encryption: false,
	app_name: 'THEIA-ADMIN',
	api_url: 'https://api.theia.flexsin.org/api/v1/', // for live server
	// api_url: "http://c7a3e388d049.ngrok.io/api/v1/", for ng rok
	// api_url: "http://100.100.7.148:8000/api/v1/",
	request_encode_key: 'z%C*F-JaNdRgUkXp2s5v8y/B?D(G+KbP',
	s3_bucket_name: 'quickfix-stage',
	stripe_key:
		'pk_test_51HxYgiK4JPfOwpchD56XeaoDyupTvbmET8T0JrEdHhYWR7VQqcSM3F4b3cnvQOdp2c0GtGwNTXoVxiizlJrzEhcN00v5tBfE83',
	region: 'ap-southeast-1',
	bucket_access_key: 'AKIAWK7HWGRLSFGQ3GNK',
	bucket_secret_key: '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',
	cookiesOptions: {
		storeUnencoded: true, // false
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
