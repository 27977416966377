/**
 * API path
 *
 * @format
 */

/**
 * Login
 *
 * @Method : POST
 * @Params :  {
 *              email: string,
 *              password: string
 *            }
 * @header : {Authorization(Bearer token): false, login_id: false}
 *
 */
export const LOGIN_API = 'login';

/**
 * LogOut
 *
 * @Method : GET
 * @Params :  { }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const LOGOUT_API = 'logout';

/**
 * Forgot Password
 *
 * @Method : POST
 * @Params :  {
 *              email: string,
 *              user_type: string
 *            }
 * @header : {Authorization(Bearer token): false, login_id: false}
 *
 */
export const FORGOT_PASSWORD_API = 'forget_password';

/**
 * Reset Password
 *
 * @Method : POST
 * @Params :  {
 *              user_id: number,
 *              password: string,
 *              confirm_password
 *            }
 * @header : {Authorization(Bearer token): false, login_id: false}
 *
 */
export const RESET_PASSWORD_API = 'reset_password';

/**
 * Send Email
 *
 * @Method : POST
 * @Params :  {
 *              email: string
 *            }
 * @header : {Authorization(Bearer token): false, login_id: false}
 *
 */
export const SEND_EMAIL_API = 'send_email';

/**
 * OTP Verification
 *
 * @Method : POST
 * @Params :  {
 *              otp: string (6 digit code),
 *              email: string
 *            }
 * @header : {Authorization(Bearer token): false, login_id: false}
 *
 */
export const VERIFY_OTP_API = 'verify_otp';

/**
 * Change Password
 *
 * @Method : POST
 * @Params :  {
 *               current_password,
                 password,
                 confirm_password
 *            }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const CHANGE_PASSWORD_API = 'change_password';

/**
 * Create Category
 *
 * @Method : POST
 * @Params :  {
 *               category_name,
                 image_name,
                 dir_name,
                 parent_id
 *            }
 * @header : {Authorization(Bearer token): true, login_id: true}
 *
 */
export const CREATE_CATEGORY_API = 'category/create';

/**
 * Update Category
 *
 * @Method : POST
 * @Params :  {
 *               category_name,
                 image_name,
                 dir_name,
                 parent_id
 *            }
 * @header : {Authorization(Bearer token): true, login_id: true}
 *
 */
export const UPDATE_CATEGORY_API = 'category/update/';

/**
 * Category List
 *
 * @Method : GET
 * @Params :  { }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const CATEGORY_LIST_API = 'category/list';

/**
 * Category List PARANT
 *
 * @Method : GET
 * @Params :  { }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const CATEGORY_LIST_PARANT_API = 'category/list/parent';

/**
 * Delete Category
 *
 * @Method : DELETE
 * @Params :  { }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const DELETE_CATEGORY_API = 'category/delete/';

/**
 * Get All Users
 *
 * @Method : GET
 * @Params :  { }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const GET_ALL_USERS_API = 'users';

/**
 * Update Users
 *
 * @Method : POST
 * @Url : id
 * @Params :  { }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const UPDATE_USERS_API = 'user/update/';

/**
 * Delete Users
 *
 * @Method : DELETE
 * @Url : id
 * @Params :  { }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const DELETE_USERS_API = 'user/delete/';

/**
 * Create Role
 *
 * @Method : POST
 * @Params :  {
 *                  name;
 *                  permission;
 *                  guard_name;
 *  }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const CREATE_ROLE_API = 'role/create';

/**
 * Update Role
 *
 * @Method : POST
 * @Url : id
 * @Params :  {
 *                  name;
 *                  permission;
 *  }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const UPDATE_ROLE_API = 'role/update/';

/**
 * Get Role
 *
 * @Method : GET
 * @Params :  {
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const GET_ROLE_API = 'roles';

/**
 * Delete Role
 *
 * @Method : DELETE
 * @Params :  {
 *                  role id with url
 *             }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const DELETE_ROLE_API = 'role/delete/';

/**
 * Create Premission
 *
 * @Method : POST
 * @Params :  {
 *                  name;
 *                  guard_name;
 *  }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const CREATE_PREMISSION_API = 'permission/create';

/**
 * Get Premission
 *
 * @Method : GET
 * @Params :  {
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const GET_PREMISSION_API = 'permission';

/**
 * Create Product
 *
 * @Method : POST
 * @Params :  {
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const CREATE_PRODUCT_API = 'product/create';

/**
 * Update Product
 *
 * @Method : POST
 * @Params :  {
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const UPDATE_PRODUCT_API = 'product/update/';

/**
 * Get Product
 *
 * @Method : GET
 * @Params :  {
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const GET_PRODUCT_API = 'product/list';

/**
 * Delete Product
 *
 * @Method : DELETE
 * @Params :  {
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const DELETE_PRODUCT_API = 'product/delete/';

/**
 * Get Product Attribute
 *
 * @Method : GET
 * @Params :  {
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const GET_PRODUCT_ATTRIBUTE_API = 'product/attribute/list';

/**
 * create Product Attribute
 *
 * @Method : POST
 * @Params :  {
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const CREATE_PRODUCT_ATTRIBUTE_API = 'product/attribute/create';
/**
 * UPDATE Product Attribute
 *
 * @Method : POST
 * @Params :  {
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const UPDATE_PRODUCT_ATTRIBUTE_API = 'product/attribute/update/';
/**
 * Delete Attribute
 *
 * @Method : DELETE
 * @Params :  { }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const DELETE_PRODUCT_ATTRIBUTE_API = 'product/attribute/delete/';

/**
 * Create Staff Member
 *
 * @Method : POST
 * @Params :  {}
 *
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const CREATE_STAFF_MEMBER = 'staff/create';

/**
 * Update Staff Member
 *
 * @Method : POST
 * @url : id
 * @Params :  {}
 *
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const UPDATE_STAFF_MEMBER = 'staff/update/';

/**
 * Get Staff Member
 *
 * @Method : GET
 * @Params :  {}
 *
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const GET_STAFF_MEMBER_LIST = 'staff/list';

/**
 * Get Staff Member
 *
 * @Method : GET
 * @url : id
 * @Params :  {}
 *
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const DELETE_STAFF_MEMBER = 'staff/delete/';

/**
 * Get add store
 *
 * @Method : Post
 * @Params :  {}
 *
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const ADD_NEW_STORE = 'store/create';

/**
 * store listing
 *
 * @Method : Get
 * @Params :  {}
 *
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const STORE_LISTING = 'store/list';

/**
 * Country list
 *
 * @Method : GET
 * @Params :  {
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const GET_COUNTRY_API = 'country/list';

/**
 * STATE list by country
 *
 * @Method : POST
 * @Params :  {
 * country_id
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const GET_STATE_API = 'state_by_country/list';

/**
 * Update store
 *
 * @Method : POST
 * @Params :  {
 * country_id
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const UPDATE_STORE = 'store/update/';

/**
 * DEletee store
 *
 * @Method : DELETE
 * @Params :  {
 * country_id
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const DELETE_STORE = 'store/delete/';

/**
 * BANK list by country
 *
 * @Method : POST
 * @Params :  {
 * country_id
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const GET_BANK_API = 'bank/list';

/**
 * Store list by country
 *
 * @Method : POST
 * @Params :  {
 * country_id
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const GET_MINIMUM_STORE_LIST = 'store/list';
/**
 * Store account type
 *
 * @Method : POST
 * @Params :  {
 * country_id
 *              }
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const GET_ACCOUNT_TYPE = 'account/type/list';

/**
 * Attribute list api
 *
 * @Method : GET
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const GET_ATTRIBUTE_VALUE = 'product/attribute/value/list';
/**
 * Attribute list api
 *
 * @Method : POST
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const CREATE_ATTRIBUTE_VALUE = 'product/attribute/value/create';

/**
 * Attribute list api
 *
 * @Method : GET
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const UPDATE_ATTRIBUTE_VALUE = 'product/attribute/value/update/';
/**
 * Attribute list api
 *
 * @Method : POST
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const DELETE_ATTRIBUTE_VALUE = 'product/attribute/value/delete/';
/**
 * Get Attribute list by category
 *
 * @Method : POST
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const GET_ATTRIBUTE_BY_CATEGORY = 'category/attributes';

/**
 * Create Sub Admin
 *
 * @Method : POST
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const CREATE_SUB_ADMIN = 'subadmin/create';

/**
 * List Sub Admin
 *
 * @Method : POST
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const SUB_ADMIN_LIST = 'subadmin/list';
/**
 * Delete Sub Admin
 *
 * @Method : POST
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const SUB_ADMIN_DELETE = 'subadmin/delete/';
/**
 * Edit Sub Admin
 *
 * @Method : POST
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const SUB_ADMIN_UPDATE = 'update_subadmin/';

/**
 * user otp verification
 *
 * @Method : POST
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const USER_OTP_VERIFICATION = 'user/verify/otp';
/**
 * Create variant
 *
 * @Method : POST
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const CREATE_PRODUCT_VARIANT = 'product/create/variant';
// get product variant
export const GET_PRODUCT_VARIANT = 'product/variant/list';
/*Delete Varient*/
export const DELETE_PRODUCT_VARIANT = 'variant/delete/';
/*Update Varient*/
export const UPDATE_PRODUCT_VARIANT = 'variant/update/';
/*get discount list*/
export const GET_DISCOUNT_LIST = 'discount/type/list';
/**
 * Create Promocode
 *
 * @Method : POST
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const CREATE_PROMOCODE = 'create/coupon';
// get PROMOCODE
export const GET_PROMOCODE = 'coupon/list';
/*Delete PROMOCODE*/
export const DELETE_PROMOCODE = 'coupon/delete/';
/*Update PROMOCODE*/
export const UPDATE_PROMOCODE = 'coupon/update/';

/*storeList api*/
export const store_List = 'stores/list';

//display category
export const SIDEBAR_CATEGORY = 'sidebar_category';
export const UPDATE_SIDEBAR_CATEGORY = 'create_update_sidebar_category';

export const DROPDOWN_CATEGORY = 'dropdown_category';

export const ORDER_LIST = 'admin/orders/list';

export const SINGLE_ORDER_DETAILS = 'admin/order-detail/';

/**
 * Get Attribute list by category
 *
 * @Method : GET
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */

export const ORDERS_REPORT = 'admin/orders-report';

/**
 * Get Attribute list by category
 *
 * @Method : GET
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */

export const SALES_REPORT = 'admin/sales-report';

/**
 * Get Attribute list by category
 *
 * @Method : GET
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */

export const SAVE_PRODUCT_IN_DRAFT = 'product/save-in-draft';

/**
 *
 *
 * @Method : POST
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const SAVE_PROMOCODE_IN_DRAFT = 'coupon/save-in-draft';

/**
 *
 *
 * @Method : POST
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const SAVE_VARIANT_IN_DRAFT = 'product/create/draft-variant';

/**
 *
 * @Method : POST
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */
export const SAVE_SUBADMIN_IN_DRAFT = 'subadmin/add-in-draft';

/**
 * get vendor list
 * @Method : GET
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */

export const VENDOR_LIST = 'admin/vendors';
export const VENDOR_STATUS_CHANGE = 'admin/vendor/updateStatus';

/**
 * get vendor details
 * @Method : GET
 * @header : {Authorization(Bearer token): true, login_id: false}
 *
 */

export const GET_VENDOR_DETAILS = 'admin/vendor/detail';

export const VENDOR_DETAIL_UPDATE = 'admin/vendor/update';

export const SAVE_STORE_PROGRESS = 'store/add-in-draft';

export const CHANGE_ORDER_STATUS = 'order/change_status';

export const GET_PAYMENT_METHODS_LIST = 'get/payment_method';

export const SETUP_INTENT = 'payment/setup_intent';

export const REMOVE_CARD = 'payment_method/remove';

export const CREATE_SUBSCRIPTION_PLAN = 'admin/add-plan';

export const GET_SUBSCRIPTION_PLAN_LIST = 'admin/get-all-plans';

export const SUBSCRIBE_TO_SUBSCRIPTION = 'admin/subscription';
export const CANCEL_SUBSCRIPTION = 'admin/cancel-subscription';

export const ACTIVE_SUBSCRIPTION = 'admin/subscription-list';
export const UPDATE_SUBSCRIPTION = 'admin/update-subscription';
export const SAVE_STAFF_MEMBER_IN_DRAFT = 'staff/save-staff_member-in-draft';
export const BULK_CSV_UPLOAD = 'import/product';
export const CMS_CREATE = 'cms/create';
export const CMS_GET = 'cms';
export const CMS_UPDATE = 'cms/update/';
export const CMS_ACTIVE = 'cms/activate/';
